import React from "react"
import { Link } from "gatsby"

import classNames from "classnames"

const ContentfulLink = ({
  className,
  ctaClassName,
  activeClassName,
  children,
  openInNewTab,
  linkText, //not using this currently
  internalLink,
  externalLink,
  emailLink,
  type,
  onClick,
}) => {
  const cta = type === "CTA"
  let linkClassNames = classNames(className, { [ctaClassName]: cta })

  if (!internalLink && !externalLink && !emailLink) return <span
    role="presentation"
    className={linkClassNames}
    onClick={onClick}
  >
    {children}
  </span>
  else if (internalLink) {
    //prioritize the internal link if for some reason both are provided
    externalLink = false
    emailLink = false
  }
  return (
    <>
      {internalLink && (
        <Link
          className={linkClassNames}
          target={openInNewTab ? '_blank' : '_self'}
          to={internalLink}
          onClick={onClick}
          activeClassName={activeClassName}
        >
          {children}
        </Link>
      )}

      {externalLink && (
        <a
          className={linkClassNames}
          href={externalLink}
          target={openInNewTab || externalLink ? '_blank' : '_self'}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {children}
        </a>
      )}

      {emailLink && (
        <a
          className={linkClassNames}
          href={`mailto:${emailLink}`}
          onClick={onClick}
        >
          {children}
        </a>
      )}
    </>
  )
}

export default ContentfulLink
