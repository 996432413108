import React from 'react'
import PaddedContent from './paddedContent'
import ContentfulLink from './contentfulLink'
import LinkTextWithChev from './linkTextWithChev'

import styles from "styles/components/alert-bar.module.scss"

import classNames from "classnames/bind"
import { isURL } from "variables.js"
const cx = classNames.bind(styles)

const AlertContainer = ({
  closeAlert,
  buttonText,
  buttonLink,
  openInNewTab,
  title,
  content,
  image,
}) => {
  const ctaLink = {
    linkText: buttonText,
    internalLink: buttonLink,
    openInNewTab
  }
  if (isURL(buttonLink)) {
    ctaLink.externalLink = buttonLink
  } else {
    ctaLink.internalLink = buttonLink
  }
  const onClickClose = () => {
    if (typeof closeAlert === 'function') {
      closeAlert()
    }
  }

  return (
    <div className={cx('container')}>
      <div className={cx('imageWrapper')}>
        <div className={cx('innerImageWrapper')}>
          <img src={ image?.file?.url } alt={image?.title} className={cx('image')} />
          <div className={cx('imageBackground')}>
            <div className={cx('innerBg')}></div>
          </div>
        </div>
      </div>
      <div className={cx('rightContent')}>
        <div className={cx('contentWrapper')}>
          <div className={cx('content')}>
            <div
              dangerouslySetInnerHTML={{
                __html: content?.childMarkdownRemark?.html,
              }}
            />
          </div>
        </div>
        <div className={cx('titleWrapper')}>
          <div className={cx('title')}>
            <div className={cx('text')}>{title}</div>
            <ContentfulLink {...ctaLink} className={cx('link')}>
              <LinkTextWithChev text={ctaLink.linkText} />
            </ContentfulLink>
          </div>
        </div>
      </div>
      <button onClick={onClickClose} className={cx('closeButton')}>&#10005;</button>
    </div>
  )
}

const AlertBar = ({ alertRef, ...data }) => {
  return (
    <section ref={alertRef} className={cx('wrapper')} id="alert-bar">
      <PaddedContent customClass={cx('customContainer')}>
        <AlertContainer {...data} />
      </PaddedContent>
    </section>
  )
}

export default AlertBar