import React from "react"

const instagram = <svg xmlns="http://www.w3.org/2000/svg" width="23.134" height="23.13" viewBox="0 0 23.134 23.13">
    <path data-name="Icon awesome-instagram" d="M11.564,7.872a5.93,5.93,0,1,0,5.93,5.93A5.921,5.921,0,0,0,11.564,7.872Zm0,9.785A3.855,3.855,0,1,1,15.42,13.8,3.862,3.862,0,0,1,11.564,17.658ZM19.12,7.63a1.383,1.383,0,1,1-1.383-1.383A1.38,1.38,0,0,1,19.12,7.63Zm3.928,1.4a6.845,6.845,0,0,0-1.868-4.846,6.89,6.89,0,0,0-4.846-1.868c-1.91-.108-7.633-.108-9.543,0A6.88,6.88,0,0,0,1.944,4.182,6.867,6.867,0,0,0,.076,9.028c-.108,1.91-.108,7.633,0,9.543a6.845,6.845,0,0,0,1.868,4.846A6.9,6.9,0,0,0,6.79,25.286c1.91.108,7.633.108,9.543,0a6.845,6.845,0,0,0,4.846-1.868,6.89,6.89,0,0,0,1.868-4.846c.108-1.91.108-7.628,0-9.538ZM20.581,20.62a3.9,3.9,0,0,1-2.2,2.2c-1.522.6-5.135.464-6.818.464s-5.3.134-6.818-.464a3.9,3.9,0,0,1-2.2-2.2c-.6-1.523-.464-5.135-.464-6.818s-.134-5.3.464-6.818a3.9,3.9,0,0,1,2.2-2.2c1.523-.6,5.135-.464,6.818-.464s5.3-.134,6.818.464a3.9,3.9,0,0,1,2.2,2.2c.6,1.523.464,5.135.464,6.818S21.185,19.1,20.581,20.62Z" transform="translate(0.005 -2.237)" />
</svg>

const linkedin = <svg xmlns="http://www.w3.org/2000/svg" width="20.623" height="20.623" viewBox="0 0 20.623 20.623">
    <path data-name="Icon awesome-linkedin" d="M19.15,2.25H1.468A1.479,1.479,0,0,0,0,3.737V21.386a1.479,1.479,0,0,0,1.468,1.487H19.15a1.483,1.483,0,0,0,1.473-1.487V3.737A1.483,1.483,0,0,0,19.15,2.25ZM6.233,19.927H3.176V10.085H6.238v9.842ZM4.7,8.741A1.772,1.772,0,1,1,6.477,6.968,1.773,1.773,0,0,1,4.7,8.741ZM17.691,19.927H14.634V15.139c0-1.142-.023-2.61-1.588-2.61-1.593,0-1.837,1.243-1.837,2.527v4.87H8.153V10.085h2.932v1.344h.041a3.219,3.219,0,0,1,2.9-1.588c3.093,0,3.669,2.039,3.669,4.691Z" transform="translate(0 -2.25)" />
</svg>

const youtube = <svg xmlns="http://www.w3.org/2000/svg" width="33.125" height="23.291" viewBox="0 0 33.125 23.291">
    <path data-name="Icon awesome-youtube" d="M33.483,8.144A4.162,4.162,0,0,0,30.554,5.2c-2.583-.7-12.942-.7-12.942-.7s-10.358,0-12.942.7A4.162,4.162,0,0,0,1.742,8.144a43.663,43.663,0,0,0-.692,8.025,43.663,43.663,0,0,0,.692,8.025,4.1,4.1,0,0,0,2.929,2.9c2.583.7,12.942.7,12.942.7s10.358,0,12.942-.7a4.1,4.1,0,0,0,2.929-2.9,43.662,43.662,0,0,0,.692-8.025,43.662,43.662,0,0,0-.692-8.025ZM14.225,21.094v-9.85l8.658,4.925-8.658,4.925Z" transform="translate(-1.05 -4.5)" />
</svg>

const twitter = <svg xmlns="http://www.w3.org/2000/svg" width="25.039" height="20.375" viewBox="0 0 25.039 20.375">
    <path data-name="Icon ionic-logo-twitter" d="M26.453,6.914a10.386,10.386,0,0,1-2.95.812,5.155,5.155,0,0,0,2.26-2.844A10.208,10.208,0,0,1,22.5,6.129a5.136,5.136,0,0,0-8.888,3.513,5.029,5.029,0,0,0,.133,1.173A14.558,14.558,0,0,1,3.154,5.439a5.147,5.147,0,0,0,1.6,6.866,5.017,5.017,0,0,1-2.335-.642v.064a5.142,5.142,0,0,0,4.123,5.041,5.171,5.171,0,0,1-1.353.18,4.846,4.846,0,0,1-.966-.1,5.145,5.145,0,0,0,4.8,3.571,10.3,10.3,0,0,1-6.383,2.2,10.413,10.413,0,0,1-1.226-.074,14.359,14.359,0,0,0,7.864,2.324A14.529,14.529,0,0,0,23.906,10.241c0-.223-.005-.446-.016-.663A10.458,10.458,0,0,0,26.453,6.914Z" transform="translate(-1.413 -4.5)" />
</svg>

const facebook = <svg xmlns="http://www.w3.org/2000/svg" width="22.124" height="21.99" viewBox="0 0 22.124 21.99">
    <path data-name="Icon simple-facebook" d="M22.124,11.062A11.062,11.062,0,1,0,9.334,21.99V14.26H6.525v-3.2H9.334V8.625c0-2.772,1.652-4.3,4.178-4.3a17.012,17.012,0,0,1,2.476.216V7.259H14.593a1.6,1.6,0,0,0-1.8,1.728v2.075h3.068l-.49,3.2H12.79v7.73A11.065,11.065,0,0,0,22.124,11.062Z" />
</svg>

const arrow = <svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="8.559" height="10.163" viewBox="0 0 8.559 10.163">
    <g data-name="invisible box">
        <rect data-name="Rectangle 1850" width="8.559" height="10.163" fill="none" />
    </g>
    <g data-name="icons Q2" transform="translate(0.331 2.848)">
        <path data-name="Path 10360" d="M14.025,19.19l-3.047-3.047a.546.546,0,0,0-.862.057.6.6,0,0,0,.057.776l3.449,3.42a.546.546,0,0,0,.8,0l3.449-3.42a.6.6,0,0,0,.057-.776.546.546,0,0,0-.862-.057Z" transform="translate(-10.001 -15.959)" />
    </g>
</svg>

const arrowMenu = <svg data-name="icons Q2" xmlns="http://www.w3.org/2000/svg" width="10.715" height="6.145" viewBox="0 0 10.715 6.145">
    <path data-name="Path 10360" d="M15.359,20.261,11.3,16.2a.727.727,0,0,0-1.148.077.8.8,0,0,0,.077,1.033l4.593,4.554a.727.727,0,0,0,1.072,0l4.593-4.554a.8.8,0,0,0,.077-1.033.727.727,0,0,0-1.148-.077Z" transform="translate(-10.001 -15.959)" />
</svg>



export default {
    instagram,
    linkedin,
    youtube,
    twitter,
    facebook,
    arrow,
    arrowMenu
}