import React from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import "what-input"
import { useBreakpoint } from "hooks/use-breakpoint"
import NoScript from "./noScript"

import styleVars from "styles/variables.scss"
import "styles/components/layout.scss"
import classNames from "classnames"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}


const Layout = ({ children, color, headerPadding, changedPage, includeSecondaryNav, page, heightAlert }) => {
  
  // const [isTablet] = useBreakpoint(styleVars.breakpointTablet, 100)
  const [isLaptop] = useBreakpoint(styleVars.breakpointLaptop, 100)
  //^ made breakpoint 100px wider to accomodate links

  // useEffect(() => {
  //   let leadlanderScript = document.querySelector("#leadlander-script")
  //   if (!leadlanderScript) {
  //     leadlanderScript = document.createElement("script")
  //     leadlanderScript.type = "text/javascript"
  //     leadlanderScript.language = "javascript"
  //     leadlanderScript.id = "leadlander-script"

  //     const inlineScript = document.createTextNode(
  //       "var llcookieless = false; var sf14gv = 21747; (function() {var sf14g = document.createElement('script'); sf14g.src = 'https://lltrck.com/lt-v2.min.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sf14g, s);})();"
  //     )
  //     leadlanderScript.appendChild(inlineScript)
  //     document.body.appendChild(leadlanderScript)
  //   }
  // }, [])

  return (
    <>
      <Header
        includeSecondaryNav={includeSecondaryNav}
        isLaptop={isLaptop}
        page={page}
        color={color ? color : "white"}
        heightAlert={heightAlert}
      />
      {/* Update main-wrapper class in layout.scss if header type changes */}
      <main
        className={classNames("main-wrapper", {
          "main-padding": headerPadding,
        })}
      >
        {children}
      </main>
      <NoScript />
      <Footer />
    </>
  )
}

export default Layout
