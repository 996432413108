

import React from "react"

const NoScript = () => {
  return (
    <noscript>
      <div id="mod-noscript" className="mod-noscript">
        <div className="table w-full h-full">
          <div className="table-cell align-middle text-center">
            <div className="container">
              <h3 >To use web better, please enable Javascript.</h3>
            </div>
          </div>
        </div>
      </div>
    </noscript>
  )
}

export default NoScript