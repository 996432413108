import { useStaticQuery, graphql } from "gatsby"
export default () => {
  let { site, contentfulSite } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            defaultTitle
            titleTemplate
            defaultDescription
            siteUrl
            defaultImageFacebook
            defaultImageTwitter
            twitterUsername
          }
        }
        contentfulSite(entryTitle: { eq: "Site" }) {
          homePage {
            metaDescription
          }
        }
      }
    `
  )
  const homeDescription = contentfulSite.homePage.metaDescription
  if (homeDescription) site.siteMetadata.defaultDescription = homeDescription
  return site.siteMetadata
}
