import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { useContentfulSite } from "hooks/use-contentful-site"
import { CSSTransition } from "react-transition-group"
import PaddedContent from "components/paddedContent"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/components/footer/footer.module.scss"
import Logo from "images/logos/encompass-footer-logo.svg"
import icons from "helpers/icons"
import GetADemoBtn from "components/header/getADemoBtn"

const Footer = ({wavy}) => {
  const site = useContentfulSite()
  const listSocials = [
    { icon: 'youtube' },
    { icon: 'instagram' },
    { icon: 'twitter' },
    { icon: 'facebook' },
    { icon: 'linkedin' },
  ]
  const [expandLogin, setExpandLogin] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpandLogin(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const expandArrow = (e) => {
    e.preventDefault()
    setExpandLogin(!expandLogin)
  }

  return (
    <footer>
      <div className={styles.wrapper}>
        <PaddedContent>
          <div className={styles.content}>
            <div className={`${styles.linksContainer} ${styles.topFooter}`}>
              <Link to={site.contactLink} aria-label="Contact">
                Contact
              </Link>
              <span aria-label="Login" className={`${styles.relative} ${styles.dFlex} ${styles.alignCenter} ${styles.loginBtn} ${styles.pointer}`} ref={ref}>
                <span role="button" tabIndex={0} onClick={expandArrow} onKeyDown={expandArrow} className={`${styles.dFlex} ${styles.alignCenter}`}>
                  Log in
                  <span className={`${styles.arrowExpand} ${expandLogin ? styles.expaned : ''}`} >
                    {icons.arrow}
                  </span>
                </span>
                <CSSTransition
                  in={expandLogin} /* dropdownIsOpen */
                  timeout={300} /* required */
                  mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
                  unmountOnExit
                  classNames={{
                    enter: styles.overflowHidden + ' ' + styles.animateDropdownEnter,
                    enterActive: styles.overflowHidden + ' ' + styles.animateDropdownEnterActive,
                    exit: styles.overflowHidden + ' ' + styles.animateDropdownExit,
                    exitActive: styles.overflowHidden + ' ' + styles.animateDropdownExitActive,
                    exitDone: styles.overflowHidden + ' ' + styles.animateDropdownExitDone,
                  }}
                >
                  <div className={styles.expandLogin}>
                    {(site?.loginLinks?.sublinks || [])
                      .map(link => <ContentfulLink key={`login-${link.id}`} className={styles.block} {...link} >{link.linkText}</ContentfulLink>)}
                  </div>
                </CSSTransition>
              </span>
            </div>
            
            <div className={styles.linksContainer}>
              <div className={styles.linkSection + ' ' + styles.logoSection}>
                <Link to="/" className={styles.logo} aria-label="Home">
                  <Logo />
                </Link>
                <div className={styles.hideMb}>
                  <GetADemoBtn/>
                </div>
              </div>
              <div className={styles.listSection}>
                {site.footerLinks.map((list, i) => (
                  <div key={i} className={styles.listPrItem}>
                    <p className={styles.title}>
                      {list.internalLink ? 
                      <ContentfulLink {...list}>
                        {list.linkText}
                      </ContentfulLink> : list.linkText}
                    </p>
                    <ul className={styles.list}>
                      {list.sublinks.map(link => (
                        <li className={styles.listItem} key={`link-${link.id}`}>
                          <ContentfulLink {...link} className={styles.link}>
                            {link.linkText}
                          </ContentfulLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${styles.showMb} ${styles.ctaButton}`}>
              <GetADemoBtn/>
            </div>
            <div className={styles.linksContainer + ' ' + styles.socialIcons}>
              {listSocials.reduce((resultArr, social, socialIdx) => {
                if (site[`${social.icon}Link`]) {
                  resultArr.push(<a key={socialIdx} href={site[`${social.icon}Link`]} rel="noopener noreferrer" target="_blank">{icons[social.icon]}</a>)
                }
                return resultArr
              }, [])
              }
            </div>

            <div className={styles.footerText}>
              {/* <p>{`© Encompass Technologies ${new Date().getFullYear()}`}</p> */}
              <p>{site.footerText.footerText}</p>
            </div>
          </div>
        </PaddedContent>
      </div>
    </footer >
  )
}

export default Footer
