import React from 'react'
import RightCarrot from "images/icons/right-carrot.svg"
import styles from "styles/components/link-text-with-chev.module.scss"

const LinkTextWithChev = ({ text }) => {
  return (
    <div className={styles.link}>
      <span>
        {text}
      </span>
        <RightCarrot className={styles.linkIcon} />
    </div>
  )
}

export default LinkTextWithChev