import { useState, useLayoutEffect } from "react"

// returns true if the screen is less than the breakpoint, false otherwise
// use like this: isMobile = useBreakpoint(styleVars.breakpointMobile)
// can provide an integer adjustment to the breakpoint, which can make it larger or smaller
export const useBreakpoint = (breakpoint, adjustment = 0) => {
  const [isBreakpoint, setIsBreakpoint] = useState(true)
  const [loaded, setLoaded] = useState(false)

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResize = () => {
    const breakpointWidth = parseInt(breakpoint) + adjustment
    if (window.innerWidth > breakpointWidth) {
      setIsBreakpoint(false)
      setLoaded(true)
    } else if (window.innerWidth <= breakpointWidth) {
      setIsBreakpoint(true)
      setLoaded(true)
    }
  }

  return [isBreakpoint, loaded]
}
