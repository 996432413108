import React, { useEffect, useState, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import ContentfulLink from "components/header/contentfulLink"
import PaddedContent from "components/paddedContent"
import LinkTextWithChev from "components/linkTextWithChev"
import { searchActiveMenu } from "./helpers"

import styles from "styles/components/header/header.module.scss"
import classNames from "classnames/bind"
import menuLogo from "images/logos/menu-logo.png"
import { Link } from "gatsby"

const cx = classNames.bind(styles)

const SublinkItem = ({ link, classExtend, activeSubMenu }) => {
  let ImageIcon
  if (link.icon) {
    const icon = link.icon.replaceAll(' ', '-').toLowerCase()
    ImageIcon = require(`images/logos-menu/${icon}.svg`)
  }

  return <ContentfulLink {...link} className={`${styles.dFlex} ${classExtend} ${styles.alignCenter} ${styles.submenuItem} ${activeSubMenu === link.internalLink ? styles.currentMenu : ''}`}>
    {link.icon && <span className={styles.iconSubmenu}>
      <ImageIcon />
    </span>}
    {link.linkText}
  </ContentfulLink>
}

const LearMoreBtn = ({ menu }) => {
  if (!(menu.buttonLink && menu.buttonText)) {
    return ''
  }
  return <div className={styles.learnMoreBtn}>
    <ContentfulLink internalLink={menu.buttonLink} >
      <LinkTextWithChev text={menu.buttonText} />
    </ContentfulLink>
  </div>
}

const ImageLogo = ({ link, className }) => {
  return <div className={className}>
    <Link to={link.buttonLink} className={`${styles.imageSubmenu}`} aria-label="Home">
      <img src={link?.logo?.file?.url} alt={link?.logo?.title} />
    </Link>
  </div>
}

const DropdownLink = ({ index, link, lastItem, pageJson, typeHeader }) => {
  const ref = useRef(null)
  const [topPosition, setTopPosition] = useState(0)
  const [activeMenu, setActiveMenu] = useState(false)
  const [activeSubMenu, setSubActiveMenu] = useState('')
  const sublinks = link.links || link.menus || link.sublinks || []
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  link.internalLink = link.buttonLink || ''

  const imageLogo = <img src={menuLogo} alt="Menu" className={styles.menuLogo} />
  const getFirstPathName = (pathname = '') => {
    return pathname.split('/').filter(path => path !== '')[0]
  }
  useEffect(() => {
    const currentMenu = searchActiveMenu(link, pageJson)
    if (currentMenu.current) {
      setSubActiveMenu(currentMenu.current.internalLink)
    }
    const pathname = window.location.pathname
    if (link.buttonLink) {
      setActiveMenu(getFirstPathName(pathname) === (link.linkText || link.title).toLowerCase().replace(new RegExp(' ', 'i'), '-'))
    }
    if (pathname.includes('blog') && (link.linkText || link.title).toLowerCase() === 'resources') {
      setActiveMenu(true)
      setSubActiveMenu('/blog/')
    }
    window.addEventListener('scroll', () => {
      setDropdownIsOpen(false)
    })
    setTimeout(() => {
      calcTopSubmenu()
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, pageJson])

  const calcTopSubmenu = () => {
    if (!ref?.current) return
    const rect = ref.current.getBoundingClientRect()
    const $header = document.getElementsByTagName('header')
    const rectHeader = $header[0].getBoundingClientRect()
    const $alertBar = document.getElementById('alert-bar')
    let heightAlert = 0

    if ($alertBar && rectHeader.top > 0 && typeHeader === 'dynamic') {
      // positionHeader = $header[0].getBoundingClientRect().top
      heightAlert = $alertBar.offsetHeight + $alertBar.getBoundingClientRect().top
    }
    setTopPosition(rect.top + rect.height - heightAlert + 21)
  }


  const openDropdown = () => {
    calcTopSubmenu()
    setDropdownIsOpen(true)
  }

  const closeDropdown = () => {
    setDropdownIsOpen(false)
  }

  return (
    <li
      role="presentation"
      className={`${styles.navItem} ${((link.menus ? sublinks.length : 1) === 1) ? styles.relative : ''}`}
      onMouseOver={openDropdown}
      onFocus={openDropdown}
      onMouseLeave={closeDropdown}
      ref={ref}
    >
      <ContentfulLink
        {...link}
        className={cx('link', 'relative', 'pointer', {
          noSublink: (sublinks || []).length === 0,
          active: dropdownIsOpen,
          menuActive: activeMenu
        })}
        ctaClassName={styles.cta}
      >
        <span>{link.linkText || link.title}</span>
        <div
          role="presentation"
          className={styles.fakeBg}
          onFocus={openDropdown}
          onMouseOver={openDropdown}
          onMouseLeave={closeDropdown}></div>
      </ContentfulLink>

      <div
        role="presentation"
        className={styles.dropdown}
        onMouseOver={openDropdown}
        onFocus={openDropdown}
        onMouseLeave={closeDropdown}
      >
        <CSSTransition
          in={dropdownIsOpen} /* index ===  */ /* dropdownIsOpen */
          // in={index === 0} /* index ===  */ /* dropdownIsOpen */
          timeout={300} /* required */
          mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
          unmountOnExit
          classNames={{
            enter: styles.overflowHidden + ' ' + styles.animateDropdownEnter,
            enterActive: styles.overflowHidden + ' ' + styles.animateDropdownEnterActive,
            exit: styles.overflowHidden + ' ' + styles.animateDropdownExit,
            exitActive: styles.overflowHidden + ' ' + styles.animateDropdownExitActive,
            exitDone: styles.overflowHidden + ' ' + styles.animateDropdownExitDone,
          }}
        >
          <div
            className={`${styles.dropdownContent} ${!link.menus && link.links && styles.listDropdown} ${styles['col' + (link.menus ? sublinks.length : 1)]} ${lastItem ? styles.lastItem : ''}`}
            style={[2, 3].includes(sublinks.length) && link.menus ? {
              top: topPosition + 'px',
              position: 'fixed'
            } : {}}
          >
            <PaddedContent>
              {!link.menus && link.links && <div className={styles.oneColWrapSubmenu}>
                <div className={`${styles.secondSubmenuItem} ${styles.submenuItemList}`}>
                  {(sublinks[0]?.links || link.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                </div>
              </div>}

              {link.menus && !link.links && <>
                {(sublinks.length === 1) && <div className={styles.oneColWrapSubmenu}>
                  {(sublinks[0]?.logo || sublinks[0].title || sublinks[0].linkText || sublinks[0]?.content?.childMarkdownRemark?.html) && link.menus && <div className={styles.descriptionSubitem1 + ' ' + styles.descriptionSubitem}>
                    {sublinks[0]?.logo && <Link to={sublinks[0].buttonLink || '/'} className={styles.imageSubmenu} aria-label="Home">
                      <img src={sublinks[0]?.logo?.file?.url} alt={sublinks[0]?.logo?.title} />
                    </Link>}
                    <p className={styles.titleSubmenu}>
                      {sublinks[0].title || sublinks[0].linkText}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sublinks[0]?.content?.childMarkdownRemark?.html,
                      }}
                    />
                    <LearMoreBtn menu={sublinks[0]} />
                  </div>}
                  <div className={`${styles.secondSubmenuItem} ${styles.submenuItemList}`}>
                    {(sublinks[0]?.links || link.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                  </div>
                  {link.menus && imageLogo}
                </div>}

                {sublinks.length === 2 && <div className={styles.twoColWrapSubmenu + ' ' + styles.relative}>
                  <div className={styles.firstWrapSubmenu + ' ' + styles.dFlex}>
                    <div className={styles.descriptionSubitem1 + ' ' + styles.descriptionSubitem}>
                      {sublinks[0]?.logo && <Link to={sublinks[0].buttonLink || '/'} className={styles.imageSubmenu} aria-label="Home">
                        <img src={sublinks[0]?.logo?.file?.url} alt={sublinks[0]?.logo?.title} />
                      </Link>}
                      <p className={styles.titleSubmenu}>
                        {sublinks[0].title || sublinks[0].linkText}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sublinks[0]?.content?.childMarkdownRemark?.html,
                        }}
                      />
                      <LearMoreBtn menu={sublinks[0]} />
                    </div>
                    <div className={`${styles.dFlex} ${styles.firstSubmenuItem} ${styles.submenuItemList} ${styles.flexWrap}`}>
                      {(sublinks[0]?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col6} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                    </div>
                  </div>

                  <div className={styles.secondWrapSubmenu + ' ' + styles.dFlex}>
                    <div className={styles.descriptionSubitem2 + ' ' + styles.descriptionSubitem}>
                      {sublinks[1]?.logo && <Link to={sublinks[1].buttonLink || '/'} className={`${styles.imageSubmenu} ${styles.connectLogo}`} aria-label="Home">
                        <img src={sublinks[1]?.logo?.file?.url} alt={sublinks[1]?.logo?.title} />
                      </Link>}
                      <p className={styles.titleSubmenu}>
                        {sublinks[1].title || sublinks[1].linkText}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sublinks[1]?.content?.childMarkdownRemark?.html,
                        }}
                      />
                      <LearMoreBtn menu={sublinks[1]} />
                    </div>
                    <div className={`${styles.secondSubmenuItem} ${styles.submenuItemList}`}>
                      {(sublinks[1]?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                    </div>
                  </div>
                  {imageLogo}
                </div>
                }

                {sublinks.length === 3 && <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.thirdWrapSubmenu}`}>
                  <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.col12} ${styles.relative} ${styles.z1}`}>
                    <div className={styles.col3}>
                      <div className={styles.descriptionSubitem1 + ' ' + styles.descriptionSubitem}>
                        {(link?.logo || sublinks[0]?.logo) && <ImageLogo link={link?.logo ? link : sublinks[0] } className={`${styles.col12} ${styles.connectLogo}`}/>}

                        <p className={styles.titleSubmenu}>
                          {sublinks[0].linkText || sublinks[0].title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sublinks[0]?.content?.childMarkdownRemark?.html,
                          }}
                        />
                        <LearMoreBtn menu={sublinks[0]} />
                      </div>
                      <div className={styles.submenuItemList}>
                        {(sublinks[0]?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                      </div>
                    </div>
                    <div className={styles.col4}>
                      <div className={styles.descriptionSubitem1 + ' ' + styles.descriptionSubitem}>
                        <p className={styles.titleSubmenu}>
                          {sublinks[1].linkText || sublinks[1].title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sublinks[1]?.content?.childMarkdownRemark?.html,
                          }}
                        />
                        <LearMoreBtn menu={sublinks[1]} />
                      </div>
                      <div className={styles.submenuItemList}>
                        {(sublinks[1]?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                      </div>
                    </div>
                    <div className={styles.col4}>
                      <div className={styles.descriptionSubitem1 + ' ' + styles.descriptionSubitem}>
                        <p className={styles.titleSubmenu}>
                          {sublinks[2].linkText || sublinks[2].title}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sublinks[2]?.content?.childMarkdownRemark?.html,
                          }}
                        />
                        <LearMoreBtn menu={sublinks[2]} />
                      </div>
                      <div className={styles.submenuItemList}>
                        {(sublinks[2]?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} classExtend={styles.col12} key={`${index}-${sublinkIdx}`} link={sublink} />)}
                      </div>
                    </div>
                  </div>
                  {imageLogo}
                </div>}
              </>}
            </PaddedContent>
          </div>
        </CSSTransition>
      </div>
    </li>

  )
}

export default DropdownLink
