import { useStaticQuery, graphql } from "gatsby"
export const useContentfulSite = () => {
  const { contentfulSite } = useStaticQuery(
    graphql`query SiteLinks {
      contentfulSite(entryTitle: {eq: "Site"}) {
        headerLinks {
          ... on ContentfulMenuItem {
            id
            title
            buttonLink
            logo {
              id
              title
              file {
                url
              }
            }
            menus {
              id
              title
              logo {
                id
                file {
                  url
                }
                title
              }
              buttonLink
              buttonText
              content {
                id
                childMarkdownRemark {
                  html
                }
              }
              links {
                id
                linkText
                internalLink
                externalLink
                icon
                openInNewTab
              }
            }
          }
          links {
            id
            linkText
            internalLink
            externalLink
            icon
            openInNewTab
          }
        }
        footerLinks {
          id
          linkText
          internalLink
          externalLink
          sublinks {
            id
            linkText
            internalLink
            externalLink
          }
        }
        facebookLink
        instagramLink
        linkedinLink
        twitterLink
        youtubeLink
        contactLink
        loginLinks {
          internalLink
          externalLink
          sublinks {
            id
            linkText
            internalLink
            externalLink
          }
        }
        getADemoLinks {
          id
          internalLink
          linkText
          externalLink
          sublinks {
            id
            linkText
            internalLink
            externalLink
          }
        }
        footerText {
          footerText
        }
        popupWithAFormHubspot {
          internal {
            content
          }
        }
        announcementBar {
          buttonText
          buttonLink
          openInNewTab
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          image {
            file {
              url
            }
            title
          }
        }
      }
    }
    `
  )
  return contentfulSite
}
