import React, { useState, useEffect, useRef } from "react"
import { CSSTransition } from "react-transition-group"
// import ContentfulLink from "./contentfulLink"
import styles from "styles/components/header/mobile-menu.module.scss"
import headerStyles from "styles/components/header/header.module.scss"
import ContentfulLink from "components/contentfulLink"
import ChevLeft from "images/icons/chev-left.svg"
import { Link } from "gatsby"
import LinkTextWithChev from "components/linkTextWithChev"
import { searchActiveMenu } from "./helpers"

const SublinkItem = ({ link, classExtend, activeSubMenu }) => {
  let ImageIcon
  if (link.icon) {
    const icon = link.icon.replaceAll(' ', '-').toLowerCase()
    ImageIcon = require(`images/logos-menu/${icon}.svg`)
  }
  return <ContentfulLink {...link} className={`${styles.dFlex} ${classExtend} ${styles.alignCenter} ${styles.submenuItem} ${activeSubMenu === link.internalLink ? styles.activeMenu : ''}`}>
    {link.icon && <span className={headerStyles.iconSubmenu}>
      <ImageIcon />
    </span>}
    {link.linkText}
  </ContentfulLink>
}

const LearMoreBtn = ({ menu }) => {
  if (!(menu.buttonLink && menu.buttonText)) {
    return ''
  }
  return <div className={styles.learnMoreBtn}>
    <ContentfulLink internalLink={menu.buttonLink} >
      <LinkTextWithChev text={menu.buttonText} />
    </ContentfulLink>
  </div>
}

const TitleLink = ({link}) => {
  return <ContentfulLink internalLink={link.buttonLink}>
    {link.linkText || link.title}
  </ContentfulLink>
}

const DropdownLinkMobile = ({ link, index, toggleSubmenu, activeMenu, pageJson }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [expandGetDemo, setExpandGetDemo] = useState(false)
  const [activeSubMenu, setSubActiveMenu] = useState('')
  const [activeMainMenu, setActiveMainMenu] = useState(false)

  const ref = useRef(null)

  let sublinks = link.links || link.menus || link.sublinks || []
  if (!link.externalLink && !link.emailLink) {
    link.internalLink = link.internalLink || link.buttonLink || '/'
  }

  if (!link.menus && !link.sublinks) {
    sublinks = [{}]
  }
  const getFirstPathName = (pathname = '') => {
    return pathname.split('/').filter(path => path !== '')[0]
  }
  useEffect(() => {
    const currentMenu = searchActiveMenu(link, pageJson)
    if (currentMenu.current) {
      setSubActiveMenu(currentMenu.current.internalLink)
    }
    const pathname = window.location.pathname
    if (link.buttonLink) {
      setActiveMainMenu(getFirstPathName(pathname) === (link.linkText || link.title).toLowerCase().replace(new RegExp(' ', 'i'), '-'))
    }
  }, [link, pageJson])

  useEffect(() => {
    setIsOpenDropdown(dropdownIsOpen && (index === activeMenu))
  }, [activeMenu, dropdownIsOpen, index])

  const toggleDropdown = (e) => {
    if ((sublinks || []).length || link.type === 'CTA') {
      if (link.type === 'CTA') {
        setExpandGetDemo(!expandGetDemo)
        return
      }
      if (!dropdownIsOpen) {
        e.preventDefault()
        setDropdownIsOpen(!dropdownIsOpen)
        toggleSubmenu(index, !dropdownIsOpen)
      } else {
        let html = document.querySelector("html")
        html.classList.remove("preventBodyScroll")
      }
    }
  }

  const backMenu = () => {
    setDropdownIsOpen(false)
    toggleSubmenu(-1, false)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpandGetDemo(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <li className={styles.navItem + ' ' + (link.type === 'CTA' ? styles.btnCta : '')}>
      <div className={link.type === 'CTA' && (`${styles.relative} ${styles.inlineBlock}`)}>
        <CSSTransition
          in={activeMenu === -1 || index === activeMenu} /* dropdownIsOpen */
          timeout={300} /* required */
          mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
          unmountOnExit
          classNames={{
            enter: styles.animateDropdownEnter,
            enterActive: styles.animateDropdownEnterActive,
            exit: styles.animateDropdownExit,
            exitActive: styles.animateDropdownExitActive,
            exitDone: styles.animateDropdownExitDone,
          }}
        ><div className={`${styles.submenuLink} ${isOpenDropdown ? styles.opened : ''} ${activeMenu !== -1 ? styles.openSubmenu : ''}`} ref={ref}>
            <div className={`${styles.dFlex} ${styles.alignCenter} ${styles.justifyBetween} ${styles.submenuLinkWrapper}`}>
              <ContentfulLink
                {...link}
                className={`${styles.link} ${expandGetDemo ? styles.activeGetDemo : ''} ${activeMainMenu ? styles.activeMenu : ''}`}
                ctaClassName={styles.cta}
                onClick={toggleDropdown}
              >
                {link.linkText || link.title}
              </ContentfulLink>
              {isOpenDropdown && <span role="button" tabIndex={0} className={`${styles.mainMenuBtn} ${styles.dFlex} ${styles.alignCenter}`} onClick={backMenu} onKeyDown={backMenu}>
                <ChevLeft className={styles.chevLeft} />
                Main Menu
              </span>}
            </div>
          </div>
        </CSSTransition>
      </div>
        <div className={styles.dropdown}>
          <CSSTransition
            in={isOpenDropdown} /* dropdownIsOpen */
            timeout={300} /* required */
            mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
            unmountOnExit
            classNames={{
              enter: headerStyles.animateDropdownEnter,
              enterActive: headerStyles.animateDropdownEnterActive,
              exit: headerStyles.animateDropdownExit,
              exitActive: headerStyles.animateDropdownExitActive,
              exitDone: headerStyles.animateDropdownExitDone,
            }}
          >
            <>
              {(sublinks.length === 1 || link.links) && <div className={styles.dropdownContent}>
                {sublinks.map(subLink => (
                  <div className={styles.submenuItemWrap}>
                    {link.menus && <div className={`${styles.dFlex} ${styles.alignCenter} ${styles.submenuItemTitle} ${!subLink?.logo ? styles.noLogo : ''}`}>
                      {subLink?.logo && <div className={`${styles.col5} ${styles.colMd12} ${styles.imageSubmenu}`}>
                        <Link to="/" aria-label="Home">
                          <img src={subLink?.logo?.file?.url} className={headerStyles.connectLogo} alt={subLink?.logo?.title} />
                        </Link>
                      </div>}
                      <div className={styles.col7 + ' ' + styles.rightTitle}>
                        <TitleLink link={subLink}/>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subLink?.content?.childMarkdownRemark?.html,
                        }}
                        className={styles.col12 + ' ' + styles.descriptionSubmenu}
                      />
                      <LearMoreBtn menu={subLink} />
                    </div>}
                    <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.submenuItemListWrap}`}>
                      {(subLink?.links || link.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} link={sublink} key={`${index}-${sublinkIdx}`} classExtend={styles.col12} />)}
                    </div>
                  </div>
                ))}
              </div>}

              {sublinks.length === 2 && <div className={styles.dropdownContent}>
                {sublinks.map((subLink, i) => (
                  <div className={styles.submenuItemWrap}>
                    <div className={`${styles.dFlex} ${styles.alignCenter} ${styles.submenuItemTitle}`}>
                      {subLink.logo && <div className={`${styles.imageSubmenu}`}>
                        <Link to="/" aria-label="Logo">
                          <img src={subLink.logo?.file?.url} alt={subLink.logo?.title} />
                        </Link>
                      </div>}
                      <div className={styles.col7 + ' ' + styles.rightTitle}>
                        <TitleLink link={subLink}/>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subLink?.content?.childMarkdownRemark?.html,
                        }}
                        className={styles.col12 + ' ' + styles.descriptionSubmenu}
                      />
                      <LearMoreBtn menu={sublinks[0]} />

                    </div>
                    <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.submenuItemListWrap}`}>
                      {(subLink?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} link={sublink} key={`${index}-${sublinkIdx}`} classExtend={(i === 0 ? styles.col6 : styles.col12) + ' '} />)}
                    </div>
                  </div>
                ))}
              </div>}

              {sublinks.length === 3 && <div className={styles.dropdownContent}>
                {sublinks.map((subLink, i) => (
                  <div className={styles.submenuItemWrap}>
                    <div className={`${styles.dFlex} ${styles.alignCenter} ${styles.submenuItemTitle} ${styles.flexWrap}`}>
                      {(subLink.logo && i === 0) && <div className={`${styles.imageSubmenu}`}>
                        <Link to="/" aria-label="Logo">
                          <img src={link?.logo?.file?.url} alt={link.logo?.title} />
                        </Link>
                      </div>}
                      <div className={styles.col12}>
                        <TitleLink link={subLink}/>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subLink?.content?.childMarkdownRemark?.html,
                        }}
                        className={styles.col12 + ' ' + styles.descriptionSubmenu}
                      />
                      <LearMoreBtn menu={sublinks[0]} />
                    </div>
                    <div className={`${styles.dFlex} ${styles.flexWrap} ${styles.submenuItemListWrap}`}>
                      {(subLink?.links || []).map((sublink, sublinkIdx) => <SublinkItem activeSubMenu={activeSubMenu} link={sublink} key={`${index}-${sublinkIdx}`} classExtend={styles.col12} />)}
                    </div>
                  </div>
                ))}
              </div>}
            </>
          </CSSTransition>
        </div>
    </li >
  )
}

export default DropdownLinkMobile
