import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PaddedContent from "components/paddedContent"
import DropdownLinkMobile from "components/header/dropdownLinkMobile"
import { useContentfulSite } from "hooks/use-contentful-site"
import { CSSTransition } from "react-transition-group"
import ContentfulLink from "components/header/contentfulLink"

import styles from "styles/components/header/mobile-menu.module.scss"
//use headerStyles to match location of logo and button in the top part of the menu
import headerStyles from "styles/components/header/header.module.scss"
import Logo from "images/logos/encompass-header-logo-mb.svg"
import Close from "images/icons/close-menu.svg"
import icons from "helpers/icons"
import menuLogo from "images/logos/menu-logo.png"
import GetADemoBtn from "components/header/getADemoBtn"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

/**
 * Mobile Menu
 * @param {[Object]} links list of link objects
 * @param {Function} closeMenu function to close the menu
 */
const MobileMenu = ({ isOpen, closeMenu, handleMenuClick, pageJson }) => {
  const site = useContentfulSite()
  const [activeMenu, setActiveMenu] = useState(-1)
  const [openSubMenu, setOpenSubmenu] = useState(false)
  const [expandLogin, setExpandLogin] = useState(false)
  const imageLogo = <img src={menuLogo} alt="Menu" className={styles.menuLogo} />
  const ref = useRef(null)

  const toggleSubmenu = (index, dropdownIsOpen) => {
    setActiveMenu(!dropdownIsOpen ? -1 : index)
    setOpenSubmenu(dropdownIsOpen)
  }

  const expandArrow = (e) => {
    e.preventDefault()
    setExpandLogin(!expandLogin)
  }

  useEffect(() => {
    if (isOpen) {
      setActiveMenu(-1)
      setOpenSubmenu(false)
    }
  }, [isOpen])

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpandLogin(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <CSSTransition
      in={isOpen}
      mountOnEnter
      unmountOnExit
      timeout={300}
      classNames={{
        enter: styles.menuEnter,
        enterActive: styles.menuEnterActive,
        exit: styles.menuExit,
        exitActive: styles.menuExitActive,
        exitDone: styles.menuExitDone,
      }}
    >
      <div className={styles.wrapper}>
        <PaddedContent>
          <div className={headerStyles.content}>
            <Link
              to="/"
              className={headerStyles.logo + ' ' + styles.logo}
              aria-label="Home"
              onClick={(e) => handleMenuClick(e, "/")}
            >
              <Logo />
            </Link>
            <button
              className={`${headerStyles.menuButton} ${styles.closeBtn}`}
              onClick={closeMenu}
              aria-label="Close Menu"
            >
              <Close />
            </button>
          </div>
          <div className={styles.topNav}>
            <ul className={styles.navList}>
              <li className={`${styles.dFlex} ${styles.alignCenter}`}>
                <Link to={site.contactLink || '/'} className={`${styles.btnTopNav} ${[pageJson?.slug, pageJson?.slug + '/', '/' + pageJson?.slug].includes(site.contactLink) ? styles.activeTopNav : ''}`}>Contact</Link>
              </li>
              <li className={`${styles.relative} ${styles.dFlex} ${styles.alignCenter}`}>
                <span role="presentation" className={`${styles.dFlex} ${styles.alignCenter} ${styles.pointer} ${styles.btnTopNav} ${expandLogin ? styles.expandedLogin : ''}`} onClick={expandArrow} ref={ref}>
                  Log in
                  <span className={`${styles.arrowExpand} ${expandLogin ? styles.expaned : ''}`} >
                    {icons.arrow}
                  </span>
                </span>
                <CSSTransition
                  in={expandLogin} /* dropdownIsOpen */
                  timeout={300} /* required */
                  mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
                  unmountOnExit
                  classNames={{
                    enter: styles.overflowHidden + ' ' + headerStyles.animateDropdownEnter,
                    enterActive: styles.overflowHidden + ' ' + headerStyles.animateDropdownEnterActive,
                    exit: styles.overflowHidden + ' ' + headerStyles.animateDropdownExit,
                    exitActive: styles.overflowHidden + ' ' + headerStyles.animateDropdownExitActive,
                    exitDone: styles.overflowHidden + ' ' + headerStyles.animateDropdownExitDone,
                  }}
                >
                  <div className={styles.expandLogin}>
                    {(site?.loginLinks?.sublinks || []).map((link, idx) => <ContentfulLink key={`login-${link.idx}`} className={styles.block} {...link}>{link.linkText}</ContentfulLink>)}
                  </div>
                </CSSTransition>
              </li>
            </ul>
          </div>
          <div className={styles.contentMenu}>
            <nav className={`${styles.nav}  ${openSubMenu ? styles.opened : ''}`}>
              <ul className={styles.navList}>
                {(site?.headerLinks || []).map((link, index) => {
                  if (link.title !== "HiddenMenu") {
                    return <DropdownLinkMobile activeMenu={activeMenu} key={link.id} index={index} link={link} toggleSubmenu={toggleSubmenu} pageJson={pageJson}/>
                  }
                  return null;
                })}
                <li className={cx('navItem', 'btnCta')}>
                  <div className={`${styles.relative} ${styles.inlineBlock}`}>
                    <GetADemoBtn />
                  </div>
                </li>
              </ul>
            </nav>
            {activeMenu === -1 && <div className={styles.contentDescription}>
              <span>Unlock the power of connection</span>
            </div>}
          </div>
        </PaddedContent>
        {imageLogo}
      </div>
    </CSSTransition>
  )
}

export default MobileMenu
