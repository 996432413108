const searchActiveMenu = (site, page) => {
  let megaLink = {
    current: null,
    links: [],
    logo: null,
  }
  let finded = false
  const slug = page?.slug || page?.path

  const findMenu = (link, parentLink) => {
    const sublinks = link.headerLinks || link.menus || link.links
    const linkSlug = link.internalLink || link.externalLink || link.buttonLink
    if ([slug, `/${slug}`, `/${slug}/`].includes(linkSlug)) {
      megaLink.current = link
      megaLink.parent = parentLink
      megaLink.links = (link.buttonLink ? link.links : parentLink?.links || parentLink?.menus) || []
      megaLink.logo = link?.logo || parentLink?.logo
      megaLink.slug = linkSlug
      finded = true
    }
    if ((sublinks || []).length && !finded) {
      for (let index = 0; index < sublinks.length; index++) {
        const sublink = sublinks[index]
        findMenu(sublink, {...link, parent: parentLink})
      }
    }
  }
  findMenu(site)
  return megaLink
}


export {
  searchActiveMenu
}