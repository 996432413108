import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "hooks/use-site-metadata"

const SEO = ({ title, description, image, article, keywords, slug }) => {
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImageFacebook,
    defaultImageTwitter,
    twitterUsername
  } = useSiteMetadata()

  const defaultImageURLFacebook = `${siteUrl}${defaultImageFacebook}`
  const defaultImageURLTwitter = `${siteUrl}${defaultImageTwitter}`
  const seo = {
    description: description || defaultDescription,
    imageFacebook: image || defaultImageURLFacebook,
    imageTwitter: image || defaultImageURLTwitter,
    url: `${siteUrl}/${(article ? `blog/${slug}` : slug) || ''}`,
    siteName: defaultTitle,
  }
  if (title) {
    if (title.includes("|")) {
      seo.title = title
    } else {
      seo.title = title + " | " + defaultTitle
    }
  } else {
    seo.title = defaultTitle
  }
  
  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={titleTemplate}
        htmlAttributes={{
          lang: `en-US`,
        }}
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.imageFacebook} />
        {keywords && <meta name="keywords" content={keywords.join(`, `)} />}
        {seo.url && <meta property="og:url" name="og:url" content={seo.url} />}
        {article ? (
          <meta property="og:type" name="og:type" content="article" />
        ) : (
          <meta property="og:type" name="og:type" content="website" />
        )}
        {seo.title && <meta property="og:title" name="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" name="og:description" content={seo.description} />
        )}
        {seo.imageFacebook && (
          <meta property="og:image" name="og:image" content={seo.imageFacebook} />
        )}
        {seo.imageFacebook && <meta property="og:image:width" name="og:image:width" content="1200" />}
        {seo.imageFacebook && (
          <meta property="og:image:height" name="og:image:height" content="1200" />
        )}

        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.imageTwitter && (
          <meta name="twitter:image" content={seo.imageTwitter} />
        )}
        {seo.siteName && (
          <meta property="og:site_name" name="og:site_name" content={seo.siteName} />
        )}
      </Helmet>
    </>
  )
}
export default SEO
