import React, { useState, useEffect, useRef } from "react"
import { useContentfulSite } from "hooks/use-contentful-site"
import styles from "styles/components/get-a-demo.module.scss"
import icons from "helpers/icons"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"

const GetADemoBtn = () => {
  const [expandGetDemo, setExpandGetDemo] = useState(false)
  const ref = useRef(null)
  const { getADemoLinks } = useContentfulSite()
  const sublinks = getADemoLinks.sublinks || []

  const onExpandGetDemo = (e) => {
    setExpandGetDemo(!expandGetDemo)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpandGetDemo(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return <button ref={ref} className={`${styles.cta} ${expandGetDemo ? styles.activeGetDemo : ''}`} onClick={onExpandGetDemo}>
    <span>{getADemoLinks.linkText || getADemoLinks.title}</span>
    <span className={`${styles.arrowExpand} ${expandGetDemo ? styles.expaned : ''}`} >{icons.arrowMenu}</span>
    <CSSTransition
      in={expandGetDemo} /* dropdownIsOpen */
      timeout={300} /* required */
      mountOnEnter /* ensures boundingbox is accurate by removing from the dom */
      unmountOnExit
      classNames={{
        enter: styles.overflowHidden + ' ' + styles.animateDropdownEnter,
        enterActive: styles.overflowHidden + ' ' + styles.animateDropdownEnterActive,
        exit: styles.overflowHidden + ' ' + styles.animateDropdownExit,
        exitActive: styles.overflowHidden + ' ' + styles.animateDropdownExitActive,
        exitDone: styles.overflowHidden + ' ' + styles.animateDropdownExitDone,
      }}
    >
      <div className={styles.wrapGetDemo}>
        <div className={styles.expandGetDemo}>
          {sublinks.map((link, idx) => <Link key={`get-demo-${idx}`} className={styles.block} to={link.internalLink}>{link.linkText}</Link>)}
        </div>
      </div>
    </CSSTransition>
  </button>
}
export default GetADemoBtn
