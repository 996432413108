export function mod(num, mod) {
  if (num >= 0) {
    return num % mod
  }
  return mod - (-num % mod)
}

export function isURL(link) {
  const urlPattern = /^(http|https):\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
  return urlPattern.test(link);
}

export  function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substring(1).match(reg);
    if (r != null) {
        return r[2];
    };
    return null;
 }
