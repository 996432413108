import React from "react"
import { useBreakpoint } from "hooks/use-breakpoint"
import styles from "styles/components/padded-content.module.scss"

const PaddedContent = ({ children, mobilePadding, fullHeight, customClass }) => {
  const [isMobile] = useBreakpoint(styles.breakpointMobile)
  const styleObject = {}
  if (isMobile && mobilePadding) {
    styleObject.padding = `0 ${mobilePadding}px` 
  }
  if (fullHeight) {
    styleObject.height = '100%'
  }
  return (
    <div
      className={[styles.paddedContent, customClass].filter(Boolean).join(' ')}
      style={styleObject}
    >
      {children}
    </div>
  )
}

export default PaddedContent
